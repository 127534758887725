<template>
  <div class="page" id="reorganize">
    <el-form class="query-form" size="small" @keyup.enter.native="getDataList(1,1)" ref="inputForm" :inline="true" :model="inputForm" label-width="100px">
      <el-form-item label="消毒日期">
        <el-date-picker
            v-model="inputForm.disinfectTime"
            type="daterange"
            clearable
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">>
        </el-date-picker>
      </el-form-item>
      <el-form-item label="藏品名称">
        <el-input
            v-model="inputForm.collectionName"
            maxlength="50"
            clearable
            placeholder="请输入藏品名称(限50字)">
        </el-input>
      </el-form-item>
      <el-form-item label="消毒人员">
        <el-input
            v-model="inputForm.disinfectUser"
            maxlength="50"
            clearable
            placeholder="消毒人员(限50字)">
        </el-input>
      </el-form-item>
      <el-form-item label="消毒方式">
        <el-select v-model="inputForm.disinfectWay" clearable placeholder="请选择消毒方式" style="width: 100%;">
          <el-option
              v-for="item in this.$dictUtils.getDictList('disinfection_method')"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label-width="0px">
        <el-button size="small" type="primary" @click="getDataList(1,1)" icon="el-icon-search">
          查询
        </el-button>
        <el-button size="small" @click="resetting()" icon='el-icon-refresh-right'>重置
        </el-button>
      </el-form-item>
      <el-form-item class="f_r">
        <el-button type="primary"
                   size="small"
                   v-show="hasPermissionButton('collection:business:disinfect:add')"
                   icon='el-icon-plus'
                   @click="addData(0)">新增
        </el-button>
      </el-form-item>
    </el-form>

    <div class="bg-white">
      <div class="text_right">
        <el-button size="small"
                   v-show="hasPermissionButton('collection:business:disinfect:batchExpor')"
                   @click="exportData(1)">
          <i class="icon-piliangdaochu iconfont buIcon"/>
          批量导出
        </el-button>
      </div>
      <el-table
          :data="dataList"
          size="small"
          v-loading="loading"
          ref="multipleTable"
          height="calc(100vh - 325px)"
          class="table"
          :row-key="'id'"
          :header-cell-style="{background:'#F9DFDF'}"
          @selection-change="selectionChangeHandle"
      >
        <el-table-column :reserve-selection="true" type="selection" width="50"/>
        <el-table-column label="序号" type="index" width="50"></el-table-column>
        <el-table-column prop="disinfectTime" sortable label="消毒时间" width="150px" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.disinfectTime | formatDate('YYYY-MM-DD') }}
          </template>
        </el-table-column>
        <el-table-column prop="disinfectUser" label="消毒人员" show-overflow-tooltip></el-table-column>
        <el-table-column prop="disinfectWay" label="消毒方式" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ $dictUtils.getDictLabel("disinfection_method", scope.row.disinfectWay, '-') }}
          </template>
        </el-table-column>
        <el-table-column prop="createUserName" label="创建者" show-overflow-tooltip></el-table-column>
        <el-table-column prop="createDate" sortable label="创建时间" width="150px">
          <template slot-scope="scope">
            {{ scope.row.createDate | formatDate }}
          </template>
        </el-table-column>
        <el-table-column prop="updateUserName" label="最后更新人"
                         show-overflow-tooltip></el-table-column>
        <el-table-column prop="updateDate" sortable label="最后更新时间" width="150px"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.updateDate | formatDate }}
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="180">
          <template slot-scope="scope">
            <el-button style="margin-bottom: 10px" size="mini" type="text"
                       @click="addData(1, scope.row)"
                       v-show="hasPermissionButton('collection:business:disinfect:detail')">
              详情
            </el-button>
            <el-button style="margin-bottom: 10px" size="mini" type="text"
                       @click="addData(2, scope.row)"
                       v-show="hasPermissionButton('collection:business:disinfect:edit')">修改
            </el-button>
            <el-button style="margin-bottom: 10px" size="mini" type="text"
                       @click="deleteDate(scope.row)"
                       v-show="hasPermissionButton('collection:business:disinfect:delete')">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="text_center">
        <el-pagination
            @size-change="sizeChangeHandle"
            @current-change="currentChangeHandle"
            :current-page="current"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="size"
            :total="total"
            background
            layout="total, sizes, prev, pager, next, jumper"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "disinfect",
  data() {
    return {
      inputForm: {
        disinfectTime: [],
        disinfectStartTime: '',
        disinfectEndTime: '',
        collectionName: '',
        disinfectUser: '',
        disinfectWay: '',
      },

      dataListSelect: [],
      loading: false,
      dataList: [],

      current: 1,
      pageNo2: 0,
      size: 10,
      total: 0,
    }
  },

  mounted() {
    let listSearch = JSON.parse(sessionStorage.getItem('listSearch'))
    if (listSearch) {
      this.inputForm = listSearch.inputForm
      this.pageNo2 = listSearch.current
      this.size = listSearch.size
    }
    this.getDataList(1, 1)
  },

  methods: {
    getDataList(type, dividePage) {
      if (type == 1) {
        this.current = 1
      }
      if (dividePage == 1) {
        this.$refs.multipleTable.clearSelection()
      }
      if (this.inputForm.disinfectTime && this.inputForm.disinfectTime.length != 0) {
        this.inputForm.disinfectStartTime = this.inputForm.disinfectTime[0]
        this.inputForm.disinfectEndTime = this.inputForm.disinfectTime[1]
      } else {
        this.inputForm.disinfectStartTime = ''
        this.inputForm.disinfectEndTime = ''
      }
      this.loading = true
      this.$axios(this.api.collection.collectiondisinfectList, {
        current: this.pageNo2 ? this.pageNo2 : this.current,
        size: this.size,
        ...this.inputForm,
        collectionId: '',
      }, 'get').then((res) => {
        if (res.status) {
          this.dataList = res.data.records
          this.$nextTick(() => {
            this.$refs.multipleTable.doLayout()
          })
          let listSearch = JSON.parse(sessionStorage.getItem('listSearch'))
          if (listSearch) {
            this.current = listSearch.current
            this.pageNo2 = 0
            sessionStorage.removeItem('listSearch')
          }
          this.total = parseInt(res.data.total)
          if (this.dataList.length == 0 && this.pageNo > 1) {
            this.pageNo--
            this.getDataList()
          }
        } else {
          this.$message.error('查询失败');
        }
        this.loading = false
      })
    },

    //重置
    resetting() {
      this.$refs.inputForm.resetFields();
      this.inputForm = {
        disinfectTime: [],
        disinfectStartTime: '',
        disinfectEndTime: '',
        collectionName: '',
        disinfectUser: '',
        disinfectWay: '',
      }
      this.getDataList(1, 1)
    },

    exportData() {
      let ids = []
      if (this.dataListSelect.length == 0) {
        this.$message.warning('请至少选择一条数据')
        return
      }

      ids = this.dataListSelect.map(item => {
        if (item) {
          return item.id
        }
      })
      let data = {
        ids: ids,
      }
      this.exportExcel(this.api.collection.collectiondisinfectExport, data, '消毒管理列表', 'post')
      this.getDataList('', 1)
    },

    //num 0新增 1详情 2修改
    addData(num, row) {
      if (num == 0) {
        this.$router.push({
          path: '/collection/business/disinfectDetail',
          query: {butType: num},
        })
      } else {
        let listSearch = {
          inputForm: this.inputForm,
          current: this.current,
          size: this.size
        }
        sessionStorage.setItem('listSearch', JSON.stringify(listSearch))
        this.$router.push({
          path: '/collection/business/disinfectDetail',
          query: {butType: num, id: row.id},
        })
      }
    },

    deleteDate(row) {
      this.$confirm(`您是否确认删除数据?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios(this.api.collection.collectiondisinfectRemove + row.id, {}, 'delete').then(data => {
          if (data && data.status) {
            this.$message.success('删除成功')
            this.getDataList('', 1)
          } else {
            this.$message.error(data.msg)
          }
        })
      })
    },

    //表格勾选数据
    selectionChangeHandle(val) {
      this.dataListSelect = val
    },

    // 展览每页数
    sizeChangeHandle(val) {
      this.size = val;
      this.getDataList(1, 1)
    },
    // 展览当前页
    currentChangeHandle(val) {
      this.current = val;
      this.getDataList('', 2);
    },
  },
}
</script>

<style scoped>
.bg-white {
  overflow-y: auto !important;
}

.table {
  .el-table__fixed-right,
  .el-table__fixed {
    height: auto !important;
    bottom: 17px !important;
  }
}
</style>